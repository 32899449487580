<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Edit Details</span>
          </v-flex>
          <v-flex xs12 pt-4>
            <span class="heading1">Basic Info</span>
          </v-flex>
          <v-flex xs12>
            <!-- BASIC INFO -->
            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Common Name</span>
                <v-text-field
                  outlined
                  placeholder="Enter Common Name"
                  v-model="commonname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Scientic Name</span>
                <v-text-field
                  outlined
                  placeholder="Enter Scientic Name"
                  v-model="scientificname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Other Name</span>
                <v-text-field
                  outlined
                  placeholder="Enter Other Name"
                  v-model="othername"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>State</span>
                <v-select
                  :items="statelist"
                  outlined
                  dense
                  hide-details
                  label="Select State"
                  v-model="state"
                  multiple
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Regional Name </span>
                <v-text-field
                  outlined
                  placeholder="Enter Regional Name"
                  v-model="reginonalname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <!-- OTHER INFO -->
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="heading1">
                <span>Other Info</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 class="subheading">
                <span>Distribution</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="distribution"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Venom Type</span>
                <v-select
                  :items="venom"
                  outlined
                  dense
                  hide-details
                  v-model="venomtype"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Family</span>
                <v-select
                  :items="familylist"
                  outlined
                  dense
                  hide-details
                  v-model="family"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Genus</span>
                <v-select
                  :items="genusitems"
                  outlined
                  dense
                  hide-details
                  v-model="genus"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Occurence</span>
                <v-select
                  :items="occurenceitems"
                  outlined
                  dense
                  hide-details
                  v-model="occurence"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>WLPA</span>
                <v-select
                  :items="WLPAitems"
                  outlined
                  dense
                  hide-details
                  v-model="wlpa"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>IUCN</span>
                <v-select
                  :items="IUCNitems"
                  outlined
                  dense
                  hide-details
                  v-model="iucn"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="subheading">
                <span>Scalation</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="scalation"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="subheading">
                <span>Description</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="description"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="subheading">
                <span>Short Description</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="shortdescription"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Assign to Expert</span>
                <v-select
                  :items="expertlist"
                  outlined
                  dense
                  hide-details
                  v-model="experts"
                  item-text="name"
                  item-value="_id"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end pt-2>
              <v-flex xs12 class="text-end">
                <v-btn depressed color="primary" @click="handlesubmit()">
                  <span>Update Data</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <!-- Images -->
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="heading1">
                <span>Add New Images</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left>
                    <v-chip
                      v-if="panCard.name"
                      class="ma-2"
                      outlined
                      color="#005f32"
                      text-color="#929292"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left pa-2>
                          <span>{{ panCard.name }} </span>
                        </v-flex>
                      </v-layout>
                    </v-chip>
                    <v-flex xs12 text-right>
                      <v-btn
                        color="success"
                        dark
                        small
                        @click="$refs.panCard.click()"
                        ><span
                          style="
                            font-size: 12px;
                            text-align: end;
                            letter-spacing: 0px;
                            opacity: 1;
                            color: white;
                          "
                        >
                          Choose Image
                        </span></v-btn
                      >
                    </v-flex>
                    <input
                      v-show="false"
                      id="file"
                      ref="panCard"
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      @change="panCardUpload($event)"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end pt-5>
              <v-flex class="subheading">
                <span>Caption</span>
                <v-text-field outlined v-model="caption" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex class="subheading" pl-lg-2 pl-md-2 pl-sm-2>
                <span>Author</span>
                <v-text-field outlined v-model="author" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex class="text-end" align-self-center pt-3>
                <v-btn depressed color="primary" @click="imagesubmit()">
                  <span>Upload Image</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <!-- view images -->
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 class="heading1">
                <span>View Images</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-5>
              <v-flex xs12 v-if="photographs && photographs.length > 0">
                <v-layout
                  wrap
                  justify-start
                  pa-1
                  v-for="(photo, index) in photographs"
                  :key="index"
                >
                  <v-flex xs4 md3 sm4 lg3>
                    <v-img
                      :src="mediaURL + photo.url"
                     
                    ></v-img>
                  </v-flex>
                  <v-flex xs2 md1 sm2 lg1 pl-2>
                    <v-btn
                      depressed
                      small
                      color="error"
                      @click="imagedelete(photo)"
                    >
                      <span>Delete Image</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-else xs12 class="subheading">
                <span>No Images Found!</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      commonname: "",
      scientificname: "",
      othername: "",
      state: [],
      reginonalname: "",
      distribution: "",
      venomtype: "",
      family: "",
      genus: "",
      occurence: "",
      wlpa: "",
      name: "",
      panCard: "",
      iucn: "",
      scalation: "",
      description: "",
      shortdescription: "",
      expertlist: [],
      experts: "",
      familylist: [
        "Acrochordidae",
        "Colubridae",
        "Elapidae",
        "Erycidae",
        "Gerrhopilidae",
        "Homalopsidae",
        "Natricidae",
        "Pareidae",
        "Pythonidae",
        "Sibynophiidae",
        "Typhlopidae",
        "Uropeltidae",
        "Viperidae",
      ],
      venom: ["Highly Venomous", "Mildly Venomous", "Non Venomous"],
      genusitems: [
        "Acrochordus",
        "Ahaetulla",
        "Amphiesma",
        "Atretium",
        "Boiga",
        "Bungarus",
        "Calliophis",
        "Cerberus",
        "Chrysopelea",
        "Coelognathus",
        "Daboia",
        "Dendrelaphis",
        "Dieurostus",
        "Dryocalamus",
        "Echis",
        "Eryx",
        "Fowlea",
        "Gerarda",
        "Gerrhopilus",
        "Grypotyphlops",
        "Hebius",
        "Hydrophis",
        "Hypnale",
        "Indotyphlops",
        "Liopeltis",
        "Lycodon",
        "Melanophidium",
        "Naja",
        "Oligodon",
        "Ophiophagus",
        "Platyceps",
        "Platyplectrurus",
        "Plectrurus",
        "Proahaetulla",
        "Ptyas",
        "Python",
        "Rhabdophis",
        "Rhabdops",
        "Rhinophis",
        "Sibynophis",
        "Teretrurus",
        "Trimeresurus (Craspedocephalus)",
        "Uropeltis",
        "Xylophis",
      ],
      occurenceitems: ["Common", "Uncommon", "Rare"],
      WLPAitems: [
        { value: "Schedule  -I", text: "Schedule  -I" },
        { value: "Schedule  -II", text: "Schedule  -II" },
        { value: "Schedule  -III", text: "Schedule  -III" },
        { value: "Schedule  -IV", text: "Schedule  -IV" },
        { value: "Not Listed", text: "Not Listed" },
        { value: "Vulnerable (VU)", text: "Vulnerable (VU)" },
        { value: "Near Threatened (NT)", text: "Near Threatened (NT)" },
        {
          value: "Conservation Dependent (CD)",
          text: "Conservation Dependent (CD)",
        },
        { value: "Extinct (EX)", text: "Extinct (EX)" },
        { value: "Extinct in the Wild (EW)", text: "Extinct in the Wild (EW)" },
      ],
      IUCNitems: [
        { value: "Not Evaluated (NE)", text: "Not Evaluated (NE)" },
        { value: "Data Deficient (DD)", text: "Data Deficient (DD)" },
        { value: "Least Concern (LC)", text: "Least Concern (LC)" },
        {
          value: "Critically Endangered (CR)",
          text: "Critically Endangered (CR)",
        },
        { value: "Endangered (EN)", text: "Endangered (EN)" },
        { value: "Vulnerable (VU)", text: "Vulnerable (VU)" },
        { value: "Near Threatened (NT)", text: "Near Threatened (NT)" },
        {
          value: "Conservation Dependent (CD)",
          text: "Conservation Dependent (CD)",
        },
        { value: "Extinct (EX)", text: "Extinct (EX)" },
        { value: "Extinct in the Wild (EW)", text: "Extinct in the Wild (EW)" },
      ],
      statelist: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Andaman and Nicobar",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Lakshadweep",
        "Delhi",
        "Puducherry",
      ],
      id: this.$route.query.id,
      items: [],
      caption: "",
      author: "",
      photographs: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getfullData();
  },
  methods: {
    panCardUpload(event) {
      this.panCard = event.target.files[0];
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/experts",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.expertlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getfullData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/" + this.id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              var d = this.items;
              this.fid = d._id;
              this.commonname = d.name;
              this.scientificname = d.scientificName;
              this.distribution = d.distribution;
              this.scalation = d.characteristics;
              this.description = d.description;
              this.venomtype = d.venomType;
              this.family = d.family;
              this.occurence = d.occurence;
              this.state = d.states;
              this.wlpa = d.wlpa;
              this.iucn = d.iucn;
              this.shortdescription = d.shortdes;
              this.reginonalname = d.rname;
              this.othername = d.othernames;
              this.genus = d.genus;
              this.experts = d.experts;
              this.photographs = d.photographs;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.id,
          name: this.commonname,
          scientificName: this.scientificname,
          states: this.state,
          rname: this.reginonalname,
          distribution: this.distribution,
          characteristics: this.scalation,
          description: this.description,
          venomType: this.venomtype,
          family: this.family,
          occurence: this.occurence,
          wlpa: this.wlpa,
          iucn: this.iucn,
          shortdes: this.shortdescription,
          othernames: this.othername,
          genus: this.genus,
          experts: this.experts,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    imagesubmit() {
      if (!this.panCard) {
        this.msg = "Please Choose An Image";
        this.showSnackBar = true;
        return;
      }
      const formData = new FormData();
      formData.append("id", this.id);
      formData.append("photo", this.panCard);
      formData.append("caption", this.caption);
      formData.append("author", this.author);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/image/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.panCard = "";
              this.caption = "";
              this.author = "";
              window.location.reload();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    imagedelete(item) {
      this.picid = item._id;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/remove/image/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.id,
          pid: this.picid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Successfully Deleted Snake Image";
              this.showSnackBar = true;
              this.appLoading = false;
              this.getfullData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
  <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.heading1 {
  font-family: poppinsregular;
  font-size: 16px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>